$('noscript').each(function () {
    var media = this;

    switch ($(media).data('mediatype')) {
        case "youtube":
            var url = "https://www.youtube-nocookie.com/embed/" + $(media).data('youtube') + "?wmode=opaque";
            var embed = embedIframe(url);
            $(media).after(embed);
            $(media).remove();
            break;
    }
});

$('div.embeddedMedia').each(function () {
    var media = this;

    switch ($(media).data('mediatype')) {
        case "youtube":
            var url = "https://www.youtube-nocookie.com/embed/" + $(media).data('youtube') + "?wmode=opaque";
            var embed = embedIframe(url);
            $(media).after(embed);
            $(media).remove();
            break;
    }
});




function embedIframe(url) {
    if (window.app.ConsentController.tracking) {
        return '<div class="video"><iframe src="' + url + '" width="100%" height="100%" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>';
    } else {

        var configurl = $('#cookieMessage button.goconfigure').data('url')



        return '<div class="videoCookieMessage"><div class="contentHolder"><div class="inner"><svg width="120px" height="120px" viewBox="0 0 120 120"><path fill-rule="evenodd" clip-rule="evenodd" fill="#CECECE" d="M120,59.961C120,26.865,93.125,0,60,0C26.875,0,0,26.865,0,59.961C0,93.135,26.875,120,60,120C93.125,120,120,93.135,120,59.961z M24.453,83.245C20.078,76.547,17.5,68.604,17.5,59.961c0-23.44,19.062-42.518,42.5-42.518c8.672,0,16.719,2.569,23.438,7.086L24.453,83.245z M102.5,59.961c0,23.518-19.062,42.596-42.5,42.596c-8.515,0-16.484-2.57-23.203-6.932l58.906-58.636C100,43.608,102.5,51.473,102.5,59.961z"/></svg><span class="title">U kunt deze video zien als u de cookies accepteert</span><p>U heeft in de cookiemelding aangegeven liever geen cookies van YouTube geplaatst te krijgen. Deze cookies zijn nodig om de video te tonen. Als u deze video wilt bekijken, dan kunt u hier alsnog de cookies accepteren. <a href="' + configurl + '">Lees meer over het cookiebeleid</a></p><button onclick="javascript:window.app.ConsentController.agreeAll();" class="agreeCookies button">Akkoord</button></div></div></div>';
    }
}